<template>
    <v-row no-gutters dense>
        <v-col cols="5" class="left-sidebar px-12 py-8">
            <v-card light flat>
                <v-container>
                    <v-row dense>
                        <div>
                            <v-responsive elevation="0" class="pa-4">
                                <v-col cols="11" class="min-width">
                                    <v-alert class="mt-1 pt-3 pb-0 mb-2" v-if="inviteValid === true" dense prominent type="success">
                                        <h4 class="text-h4">Invite</h4>
                                        <p class="tiemposText tiemposText--bold">{{ $t('invite.lookup_invite_found') }}</p>
                                    </v-alert>
                                    <v-alert class="mt-1 pa-5" v-else-if="inviteValid === false" dense prominent type="error">
                                        <h2 class="text-h2">Invite not found</h2>
                                        <p class="tiemposText tiemposText--bold">{{ $t('invite.lookup_invite_failed') }}</p>
                                    </v-alert>
                                    <v-alert class="mt-1 pa-5" v-else dense prominent type="info">
                                        <h2 class="text-h2">Invite</h2>
                                        <p class="tiemposText tiemposText--bold">{{ $t('invite.lookup_invite') }}</p>
                                        <v-progress-linear indeterminate color="white"></v-progress-linear>
                                    </v-alert>
                                    <div v-if="inviteValid === true">
                                        <v-card v-if="!registerNewAccount" dark flat class="pt-3 pa-4 mb-3" style="border: 10px solid black;">
                                            <register-activator v-model="registerNewAccount"/>
                                        </v-card>
                                        <div>
                                            <register-or-login-with-email-form v-model="registerNewAccount"/>
                                        </div>
                                    </div>
                                </v-col>
                            </v-responsive>
                        </div>
                    </v-row>
                </v-container>
            </v-card>
        </v-col>
        <v-col cols="7" class="background-bricks px-12 py-9">
        </v-col>
    </v-row>
</template>

<script>
import RegisterActivator from '@/components/RegisterActivator';
import RegisterOrLoginWithEmailForm from '@/components/RegisterOrLoginWithEmailForm';
import { refInvites } from '@/firebase/firestore/invites';

export default {
    name: 'TheProjectInvite',

    components: {RegisterOrLoginWithEmailForm, RegisterActivator},

    data: () => ({
        registerNewAccount: false,
        inviteId: null,
        inviteValid: null,
        invite: null,
    }),

    created () {
        this.inviteId = this.$route.params['inviteId'];

        // Geen inviteId, dan ook geen geldige invite om op te zoeken
        if (!this.inviteId) {
            return setTimeout(() => this.inviteValid = false, 2500);
        }

        // Na het opzoeken van de invite geven we een melding die de gebruiker verder helpt
        // Alle meldingen worden met een vertraging getoond
        refInvites.doc(this.inviteId)
            .get()
            .then(doc => {
                if (doc.exists) {
                    setTimeout(() => this.inviteValid = true, 2400);
                    this.invite = doc.data();

                    // Sla de invite op tot de gebruiker wordt gekoppelt
                    this.$store.dispatch('invites/setInviteId', doc.id);

                    // Koppel de gebruiker indien deze ingelogged is
                    if (this.$store.getters['authentication/isAuthenticated']) {
                        this.$store.dispatch('invites/addUserToProject');
                        // TODO: redirect gebruiker naar het betreffende project
                    }
                } else {
                    // doc.data() is undefined
                    setTimeout(() => this.inviteValid = false, 1100);
                }
            })
            .catch(error => {
                setTimeout(() => this.inviteValid = false, 1100);
            });
    },

    methods: {},

    mounted () {
        this.$store.commit('SET_NAVIGATION_MINI', false);
        this.$store.commit('SET_PHOTO_DRAWER', false);
    },
};
</script>

<style scoped lang="scss">
.min-width {
    min-width: 400px;
}
</style>
